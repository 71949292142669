import * as React from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

export default function MouseOverPopover (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        className='job role_prev'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}

      >
        {props.text}
      </Typography>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {props.split ? (
          <Typography sx={{ p: 1 }}>
            {props.popText.split(',').map(str => {
              return <div>{str}</div>
            })}
          </Typography>
        ) : (
          <Typography sx={{ p: 1 }}>{props.popText}</Typography>
        )}
      </Popover>
    </div>
  )
}
