export default function dateDiff(from, to, type = "days") {
    if(!from || !to) return "Invalid Date"
    
    const fromDate = new Date(from);
    const toDate = new Date(to);

    const diffTime = Math.abs(toDate - fromDate);
    let result;
    
    switch (type) {
      case "hours":
        result = Math.ceil(diffTime / (1000 * 60 * 60 ));
        break;
      case "days":
        result = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        break;
      case "weeks":
        result = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
        break;
      case "months":
        result = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
        result -= fromDate.getMonth() + 1;
        result += toDate.getMonth();
        if (fromDate.getDate() <= toDate.getDate()) {
          result++;
        }
        break;
      default:
        throw new Error("Invalid type argument");
    }
    
    return result;
  }