import toast from '../components/Toast'
const pako = require('pako')

const api = process.env.REACT_APP_API
const api_x_key = process.env.REACT_APP_API_X_KEY

export default async function lambdaFetch (
  path,
  method,
  webToken,
  body,
  successMessage,
  creds,
  dontShowTimeoutError,
  customErrorMessage,
  skipErrorCatch
) {
  try {
    const res = await fetch(`${api}/${path}`, {
      method: method,
      headers: {
        'X-Api-Key': api_x_key,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${webToken}`
        // Authorization: `Bearer ${creds.user ? creds.user.roleToken : ''}`,
      },
      body: body
    }).then(resp => {
      if( dontShowTimeoutError) return resp.json()

      if(!resp.ok) {
        
        if(resp.status === 504) {
          throw new Error('Timeout error');
        }
        // throw new Error(`Error with status: ${resp.status}`)
     }
      return resp.json()
    })
    if(res.isPacko) {
      const unpackedData = JSON.parse(pako.inflate(res.data, { to: 'string' }))
      res.data = unpackedData
    }
    if (res) {
      if (
        res.message ===
        'User is not authorized to access this resource with an explicit deny'
      ) {
        if (path === 'login') {
          return null
        }

        toast.persist(
          'Your access token may be exipred please try logging back in.',
          'error'
        )
        creds.login()
        return null
      }
    }
    if(res.message == 'Endpoint request timed out' && !dontShowTimeoutError) {
      toast.persist('Timeout error', 'error')
      return false
    }
    if (!res.success && res.error) {
      toast.persist(res.error, 'error')
      return false
    } else if (res.success && res.data) {
      if (res.data.maxRows) {
        toast.persist(res.data.maxRows, 'warning')
      }
      return res
    } else {
      successMessage && toast.success(successMessage)
      return res
    }
  } catch (e) {
    console.log(e)
    toast.persist(customErrorMessage ? customErrorMessage : e.toString(), 'error')
  }
}
